
@import "./media_query";
@import "./variables";

.special-heading {
	font-size: 60px;
	font-family: $font_3;
	@include desktop {
		font-size: 50px;
	}
	@include tablet {
		font-size: 40px;
	}
	@include mobile {
		font-size: 35px;
	}
}

.special-heading-3 {
	font-size: 42px;
	font-family: $font_3;

	@include desktop {
		font-size: 35px;
	}
	@include tablet {
		font-size: 35px;
	}
	@include mobile {
		font-size: 30px;
	}
}
.big-heading {
	font-family: $font_2;
	font-size: 100px;
	font-weight: 700;
	color: $semi_black;
	@include desktop {
		font-size: 75px;
	}
	@include tablet {
		font-size: 60px;
	}
	@include mobile {
		font-size: 50px;
	}
}
.quote-title {
	position: relative;
	font-family: $default_font;
	font-weight: 500;
	font-size: 20px;
	padding-left: 55px;
	&::before {
    content: "";
    width: 30px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 10px;
	}
}
.heading-3 {
	font-family: $font_2;
	font-size: 24px;
	font-weight: 500;
}
.general-heading {
	font-size: 46px;
	font-weight: 700;
	font-family: $font_2;
	@include desktop {
		font-size: 35px;
	}
	@include tablet {
		font-size: 35px;
	}
	@include mobile {
		font-size: 24px;
	}
}
.heading-five {
	font-size: 20px;
}
.heading-six {
	font-family: $font_2;
	font-weight: 500;
	font-size: 16px;
}
.heading-5 {
	color: $default_color;
	font-size: 20px;
	font-weight: 400;
	a {
		display: inline-block;
		color: $default_color;
		-webkit-transition: all .4s linear 0;
		-o-transition: all .4s linear 0;
		transition: all .4s linear 0;
		&:hover {
			text-decoration: none; 
		}
	}
}
.read-more-btn-2 {
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	color: $default_color;
	-webkit-transition: all .4s linear 0s;
	-o-transition: all .4s linear 0s;
	transition: all .4s linear 0s;
	&:hover {
		text-decoration: none;
		color: $hover;
		&.right-style {
			&:before {
				border-color: $hover;
			}
		}
	}
}
.section-ptb {
	padding-top: 125px;
	padding-bottom: 125px;
	@include tablet {
		padding-top: 80px;
	    padding-bottom: 80px;
	}
	@include mobile {
		padding-top: 80px;
	    padding-bottom: 80px;
	}

}
.section-ptb-2 {
	padding-top: 100px;
	padding-bottom: 100px;	
}
.section-ptb-3 {
	padding-top: 270px;
	padding-bottom: 100px;	
	@include tablet {
	   padding-top: 200px;
       padding-bottom: 60px;
	}	
	@include mobile {
	   padding-top: 200px;
       padding-bottom: 60px;
	}
}
.section-ptb-4 {
	padding-top: 115px;
	padding-bottom: 170px;
	@include tablet {
	   padding-top: 50px;
       padding-bottom: 80px;
	}	
	@include mobile {
	   padding-top: 50px;
       padding-bottom: 80px;
	}
}

.section-ptb-5 {
	padding-top: 80px;
	padding-bottom: 90px;
	@include tablet {
	   padding-top: 40px;
       padding-bottom: 45px;
	}	
	@include mobile {
	   padding-top: 40px;
       padding-bottom: 45px;
	}
}

.section-ptb-6 {
	padding-top: 125px;
	padding-bottom: 75px;
	@include tablet {
	   padding-top: 80px;
       padding-bottom: 40px;
	}	
	@include mobile {
	   padding-top: 80px;
       padding-bottom: 40px;
	}
}

.section-ptb-7 {
	padding-top: 125px;
	padding-bottom: 135px;
	@include tablet {
	   padding-top: 70px;
       padding-bottom: 65px;
	}	
	@include mobile {
	   padding-top: 70px;
       padding-bottom: 65px;
	}
}

.section-ptb-8 {
	padding-top: 125px;
	padding-bottom: 145px;
	@include tablet {
		padding-top: 80px;
		padding-bottom: 75px;
	}
	@include mobile {
	   padding-top: 80px;
	  padding-bottom: 75px;
	}
}
.section-ptb-9 {
    padding-top: 135px;
    padding-bottom: 140px;
    @include tablet {
    padding-top: 75px;
    padding-bottom: 70px;
    }
    @include mobile {
    padding-top: 75px;
    padding-bottom: 70px;
    }
}
.mb-xs-1 {
	@include mobile {
		margin-bottom: 1rem;
	}
}
.section-pb {
	padding-bottom: 125px;
	@include tablet {
       padding-bottom: 80px;
	}	
	@include mobile {
       padding-bottom: 80px;
	}
}

.section-pt {
	padding-top: 125px;
	@include tablet {
       padding-top: 80px;
	}	
	@include mobile {
       padding-top: 80px;
	}
}
.pt-185 {
	padding-top: 185px;
	@include tablet {
       padding-top: 140px;
	}	
	@include mobile {
       padding-top: 140px; 
	}
}
.section-curve {
	position: relative;
	&::before {
    content: "";
    position: absolute;
    top: -20px;
    width: 100%;
    background-image: url(../images/black_curve.png);
    height: 25px;
  }
   &::after {
    content: "";
    position: absolute;
    bottom: -20px;
    width: 100%;
    background-image: url(../images/black_curve.png);
    height: 25px;
    -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
    z-index: 1;
  }
}

.section-curve-2 {
	position: relative;
	&:before {
	content: "";
    position: absolute;
    top: 0;
    width: 100%;
    background-image: url(../images/curve_02.png);
    height: 25px;
    -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
    z-index: 1;
	}
	&:after {
	content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: url(../images/curve_02.png);
    height: 25px;
	}
}
.section-curve-bottom {
	position: relative;
	&::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: url(../images/curve_02.png);
    height: 15px;
  }
}
.section-curve-top {
	position: relative;
	&::after {
    content: "";
    position: absolute;
    top: -24px;
    width: 100%;
    background-image: url(../images/black_curve.png);
    height: 26px;
    left: 0;
  }
}
.section-curve-bottom-2 {
	position: relative;
	&::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: url(../images/curve_02.png);
    background-color: $white;
    height: 25px;
    -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}
.read-more-btn {
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
	color: $default_color;
	padding: 15px 30px;
	border: 2px solid $default_color;
	-webkit-transition: all .4s linear 0s;
	-o-transition: all .4s linear 0s;
	transition: all .4s linear 0s;
	&:hover {
		background-color: $hover;
		color: $white;
		border-style: dashed;
		border-color: rgba($default_color,.6);
		-webkit-transition: all .4s linear 0s;
		-o-transition: all .4s linear 0s;
		transition: all .4s linear 0s;
		text-decoration: none;
	}
}
.white-btn {
	display: inline-block;
	background-color: $white;
	color: $default_color !important;
	font-weight: 500;
	font-size: 18px;
	padding: 12px 25px !important;
	-webkit-transition: all .4s linear 0s;
	-o-transition: all .4s linear 0s;
	transition: all .4s linear 0s;
	&:hover {
		text-decoration: none !important;
		background-color: $hover;
		color: $white !important;
	}
}
.view-more-btn {
	display: inline-block;
	font-family: $default_font;
	font-weight: 500;
	font-size: 18px;
	background-color: $default_color;
	padding: 15px 30px;
	-webkit-transition: all .4s linear 0s;
	-o-transition: all .4s linear 0s;
	transition: all .4s linear 0s;
	&:hover {
		text-decoration: none;
		background-color: $hover;
	}
}
.bg-semi-white {
	background-color: $semi_white;
}
.quite-bg {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 850px;
	background-repeat: no-repeat;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
    background-size: 55% 100%;
    
    @include desktop {
    	background-size: cover;
    }
}
.bg-default {
	background-color: $default_color;
}
.text-semi-black {
	color: $semi_black;
}
.text-semi-black-2 {
	color: $semi_black_2;
}
.pt-40 {
	padding-top: 40px;
}

.social-profile-2 {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			a {
				display: inline-block;
				color: $white;
				width: 30px;
				height: 30px;
				line-height: 30px;
				border-radius: 50%;
				font-size: 14px;
				text-align: center;
			}
		}
	}
}
.list-style-2 {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		border-bottom: 1px dotted;
		padding: 12px 0;
		font-size: 14px;
		&:first-child {
			padding-top: 12px;
		}
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
	}
}
.list-style-3 {
	list-style: none;
	margin: 0;
	padding: 0;	
	li {

		padding: 8px 0;
		i {
			padding-right: 10px;
		}
		.list-name {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		    -webkit-box-align: center;
		        -ms-flex-align: center;
		            align-items: center;
			color: $white;
			font-size: 14px;
		}
	}
}
.facebook-link {
	background-color: $fb_link_bg;
}
.twitter-link {
	background-color: $twitter_link_bg;
}
.instagram-link {
	background-color: $instagram_linbk_bg;
}
.linkedin-link {
	background-color: $linkedin_linbk_bg;
}

.border-top-dark {
	border-top: 1px solid $dark_3;
}