$white: #fff;
$semi_white: #f5f5f5;
$black: #222222;
$semi_black: #0D0D0D;
$semi_black_2: #292929;
$hover: #f4c430;
$semi_hover: #fff6f4;
$dark: #ddd;
$dark_2: #D4D4D4;
$dark_3: #545454;
$default_color: #292929;
$fb_link_bg: #3b5999;
$twitter_link_bg: #55acee;
$pinterest_linbk_bg: #bd081c;
$instagram_linbk_bg: #E4405F;
$linkedin_linbk_bg: #0077B5;
$default_size: 16px;
$default_font: 'Poppins', sans-serif;  
$font_2: "Cerebri Sans", sans-serif;
$font_3: 'Cookie', cursive;
$btn_padding: 10px 25px;
$bold_weight: 600;